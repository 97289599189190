import * as Sentry from "@sentry/react";
import HyperDX from '@hyperdx/browser';
import { BrowserTracing } from '@sentry/tracing'
import { AuthContextProvider } from '@infra/firebase';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { AppShell } from './App';
import GlobalStyles from './GlobalStyles';
import { reportWebVitals } from './reportWebVitals';
import { RoutesProvider } from './Routes';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: import.meta.env.VITE_APP_ENV,
  tracesSampleRate: 1.0,
});

HyperDX.init({
  url: import.meta.env.VITE_HYPERDX_URL,
  apiKey: import.meta.env.VITE_HYPERDX_API_KEY,
  service: `patients-ui-${import.meta.env.VITE_APP_ENV}`,
  tracePropagationTargets: [/`*.daslab.health`/i], // Set to link traces from frontend to backend requests
  consoleCapture: false, // Capture console logs (default false)
  advancedNetworkCapture: false, // Capture full HTTP request/response headers and bodies (default false)
});

const container = document.getElementById('root') as Element | DocumentFragment;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppShell>
      <Suspense fallback={<>...</>}>
        <AuthContextProvider>
          <GlobalStyles />
          <RoutesProvider />
        </AuthContextProvider>
      </Suspense>
    </AppShell>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
