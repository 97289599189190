import { Navigate, Outlet } from "react-router-dom";
import {observer} from "mobx-react";
import {routes} from "@/route.types";
import {app, getAuth, useAuthState} from "@infra/firebase";
import {authStore} from "@/shared/stores";
import {useMemo} from "react";


const OnlyUnauthorizedRoute = () => {
  const [user, loading] = useAuthState(getAuth(app))

  if(loading) return null;
  return user ? <Navigate to={routes.HomePage} /> :  <Outlet />;
};

export default observer(OnlyUnauthorizedRoute);
