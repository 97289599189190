import axios, {AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosHeaders, AxiosError} from 'axios';
import {auth, signOut} from "@infra/firebase";
import {apiConfig} from '@infra/observable-core'

export interface IConstructorConfig {baseUrl?: string, withAccessToken?: boolean, withRedirectionOnUnauthorized?: boolean}
export interface IAxiosInstanceAPI {instance: AxiosInstance}

export class AxiosInstanceAPI implements IAxiosInstanceAPI
{
  public instance: AxiosInstance;

  constructor({
    baseUrl = apiConfig.baseUrl,
    withAccessToken = true,
    withRedirectionOnUnauthorized = true,
  }: IConstructorConfig)
  {
    this.instance = axios.create({baseURL: baseUrl});
    withAccessToken && this.bindRequestInterceptors();
    withRedirectionOnUnauthorized && this.bindResponseInterceptor();
  }

  private bindRequestInterceptors = (): void =>
  {
    this.instance.interceptors.request.use(
      async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> =>
      {
        if (!auth.currentUser) return Promise.reject({ response: { status: 401, message: 'User not authenticated' } });
        const accessToken = await auth.currentUser.getIdToken();
        if (!accessToken) return Promise.reject({ response: { status: 401, message: 'Missing accessToken' } });
        config.headers = config.headers || new AxiosHeaders();
        config.headers.set('Authorization', `Bearer ${accessToken}`);
        return config;
      },
      error =>
      {
        console.error(error);
        return Promise.reject(error);
      }
    );
  }

  private bindResponseInterceptor = (): void =>
  {
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError): Promise<never> =>
      {
        if (error.response?.status === 401 || error.response?.status === 403) await signOut(auth);
        return Promise.reject(error);
      }
    );
  }
}

