import { LayoutWrapper } from '@infra/common-components'
import { lazy } from 'react'
import { LayoutWithOutlet } from './shared/router/LayoutWithOutlet'
import ProtectedRoute from './shared/router/ProtectedRoute'
import OnlyUnauthorizedRoute from './shared/router/OnlyUnauthorizedRoute'

const HomePage = lazy(() => import('./pages/homepage'))
const DiagnosticPrescriptions = lazy(() => import('./pages/diagnostic-prescriptions'))
const Support = lazy(() => import('./pages/support'))

const CollectPrescription = lazy(() => import('./pages/collect-prescription'))
const CollectPrescriptionFailure = lazy(() => import('./pages/collect-prescription-failure'))
const ViewPrescription = lazy(() => import('./pages/view-prescription'))
const OrderDetail = lazy(() => import('./pages/orders/order-detail'))
const OrderConnect = lazy(() => import('./pages/orders/orders-connect'))
const OrderFollowup = lazy(() => import('./pages/orders/order-followup'))

const SignIn = lazy(() => import('./pages/sign-in'))
const Login = lazy(() => import('./pages/authentication/login'))
const Register = lazy(() => import('./pages/authentication/register'))
const AuthCheckInBox = lazy(() => import('./pages/authentication/checkInBox'))
const AuthConfirmEmail = lazy(() => import('./pages/authentication/confirm-email'))
const AuthResetPassword = lazy(() => import('./pages/authentication/reset-password/reset-password'))
const AuthSetNewPassword = lazy(() => import('./pages/authentication/reset-password/set-new-pasword'))
const AuthResetCheckInBox = lazy(() => import('./pages/authentication/reset-password/reset-check-in-box'))

const ShareResults = lazy(() => import('./pages/share-results'))
const ShareInstructions = lazy(() => import('./pages/share-instructions'))

const IdentityConfirm = lazy(() => import('./pages/identity/identityConfirm'))
const IdentityCheckInbox = lazy(() => import('./pages/identity/identityCheckInbox'))

export const LazyRoutes = {
  DiagnosticPrescriptions,
  CollectPrescription,
  CollectPrescriptionFailure,
  HomePage,
  OrderDetail,
  OrderConnect,
  OrderFollowup,
  SignIn,
  Login,
  Register,
  AuthCheckInBox,
  AuthConfirmEmail,
  AuthResetPassword,
  AuthSetNewPassword,
  AuthResetCheckInBox,
  Support,
  ShareResults,
  ShareInstructions,
  ProtectedRoute,
  LayoutWrapper,
  LayoutWithOutlet,
  ViewPrescription,
  IdentityConfirm,
  IdentityCheckInbox,
  OnlyUnauthorizedRoute
}
