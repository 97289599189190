import { Global, css, useTheme, FeatureBeen } from '@infra/ui-design-system'
import {renderToStaticMarkup} from "react-dom/server";
import {observer} from "mobx-react";
import {globalStore} from "@/shared/stores/GlobalStore/global.store";

export const GlobalStyles = () => {
  const {bodyBottomGutter} = globalStore;
  const svgString = encodeURIComponent(renderToStaticMarkup(<FeatureBeen />));
  const {breakpoints} = useTheme();

  return (
    <Global
      styles={css`
        body {
          margin: 0;
          background-size: 100% auto;
          background-position: bottom right;
          background-repeat: no-repeat;
          background-attachment: fixed;
          ${breakpoints.up('md')}{background-image: url("data:image/svg+xml,${svgString}");}
          ${breakpoints.down('md')}{margin-bottom: ${bodyBottomGutter ? '8' : '0'}rem;}
        }
        html strong {font-weight: 600}
        sup {vertical-align: top;}
      `}
    />
  )
}

export default observer(GlobalStyles)
