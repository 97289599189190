import {createContext, ReactNode} from 'react'
import { Store } from '../StateStore/StateStore'
import { Action } from '../StateStore/StateStore.interface'
import { UserActionTypes, UserStoreState } from './UserStore.interface'

export const initialUserStoreState: UserStoreState = {
  error: null,
  user: null,
}

export const userStoreReducer = (state: UserStoreState, action: Action<UserActionTypes>) => {
  switch (action.type) {
    case UserActionTypes.SET_USER_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      }
    case UserActionTypes.SET_USER_INPUTS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export const userStore = new Store<UserStoreState, UserActionTypes>(userStoreReducer, initialUserStoreState)

export const UserStoreContext = createContext(userStore)

export const UserStoreProvider = ({ children }: {children: ReactNode}) => {
  return <UserStoreContext.Provider value={userStore}>{children}</UserStoreContext.Provider>
}
