import {action, makeObservable, observable} from 'mobx';

class GlobalStore
{
  bodyBottomGutter: boolean = false;

  constructor()
  {
    makeObservable(this, {
      bodyBottomGutter: observable,
      setBodyBottomGutter: action,
    });
  }

  setBodyBottomGutter = (bodyBottomGutter: boolean) => {
    this.bodyBottomGutter = bodyBottomGutter;
  }

}

export const globalStore: GlobalStore = new GlobalStore();
