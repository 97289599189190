import { action, makeObservable, observable } from 'mobx';
import {portal, IAuthStore} from './auth.types';

class AuthStore implements IAuthStore {

  email: string = '';
  portal: portal = 'prevention';
  hasJustLogin: boolean = false;

  constructor()
  {
    makeObservable(this, {
      email: observable,
      portal: observable,
      hasJustLogin: observable,
      setEmail: action,
      setHasJustLogin: action,
      setPortal: action,
    });
  }

  setEmail = email => {this.email = email}
  setPortal = portal => {this.portal = portal}
  setHasJustLogin = hasJustLogin => {this.hasJustLogin = hasJustLogin}
}

export const authStore: IAuthStore = new AuthStore();
