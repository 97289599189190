import i18n from 'i18next';
import { initializeI18n } from '@infra/application-shell';
import { i18nextConfig } from './i18next';
import dayjs from 'dayjs';
import en from "dayjs/locale/en";
import es from "dayjs/locale/es";
import de from "dayjs/locale/de";

const dayjsLocales = {en, es, de};
const version = 'latest';

initializeI18n(i18nextConfig, { en: version, es: version, de: version }, version)
dayjs.locale(dayjsLocales[i18n.language.split('_')[0]]);

export { i18n };
