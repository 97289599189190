import { action, makeObservable, observable } from 'mobx';
import {apiConfig, AxiosInstanceAPI} from "@infra/observable-core";
import {Configuration} from "@infra/daslab-sdk";
import {AxiosSDKError, ErrorHandler} from "@infra/helpers";

export interface IBaseStore<T> {
  executing: boolean;
  initiated: boolean;
  errors: string[];
  api: T;
  setInitiated: (executing: IBaseStore<T>['initiated']) => void;
  setExecuting: (executing: IBaseStore<T>['executing']) => void;
  setErrors: (executing: AxiosSDKError) => void;
}

export abstract class BaseStore<T> implements IBaseStore<T> {

  executing: IBaseStore<T>['executing'] = false;
  initiated: IBaseStore<T>['initiated'] = false;
  errors: IBaseStore<T>['errors'] = [];
  api: IBaseStore<T>['api'];

  protected constructor(CurrentApi: any)
  {
    makeObservable(this, {
      executing: observable,
      initiated: observable,
      errors: observable,
      setErrors: action,
      setExecuting: action,
      setInitiated: action,
    });

    const baseUrl: string = apiConfig.baseUrl.replace(/\/v1\//g, '');
    const axiosInstanceAPI: AxiosInstanceAPI = new AxiosInstanceAPI({baseUrl});
    this.api = CurrentApi ? new CurrentApi(new Configuration(), baseUrl, axiosInstanceAPI.instance) : null;
  }

  setExecuting = (executing: IBaseStore<T>['executing']) => {this.executing = executing}
  setInitiated = (initiated: IBaseStore<T>['initiated']) => {this.initiated = initiated}
  setErrors = (e: AxiosSDKError | string[]) =>
  {
    const isArray = Array.isArray(e);
    const hasErrors = isArray ? e.length > 0 : true;

    if (hasErrors) console.error(e);
    this.errors = isArray ? e : new ErrorHandler(e).messages;
  }
}
