import {action, computed, makeObservable, observable} from 'mobx';
import {BaseStore} from '@infra/state-stores';
import {DiagnosticResultsApi, GetAttachmentResponseDTO} from "@infra/daslab-sdk";
import {IResultsStore} from './results.types'
import {attachmentsService} from "@infra/observable-core";
import {AxiosSDKError, ErrorHandler} from "@infra/helpers";

class ResultsStore extends BaseStore<DiagnosticResultsApi> implements IResultsStore
{
  result: IResultsStore['result'] = null;
  attachment: IResultsStore['attachment'] = null;

  constructor()
  {
    super(DiagnosticResultsApi);
    makeObservable(this, {
      result: observable,
      attachment: observable,
      fullName: computed,
      setResult: action,
      setAttachment: action,
    });
  }

  get fullName()
  {
    if(!this.result?.patient) return '';
    return `${this.result?.patient?.firstName} ${this.result?.patient?.lastName}`;
  }
  setResult = (result: IResultsStore['result']) => {this.result = result};
  setAttachment = (attachment: IResultsStore['attachment']) => {this.attachment = attachment}
  getResultByContainerId = async (containerId: string) => {
    try
    {
      const {data} = await this.api.getDetailsByContainerId(containerId);
      const attachment: GetAttachmentResponseDTO|null = !data.attachmentId ? null : await attachmentsService.getById(data.attachmentId);
      this.setAttachment(attachment);
      this.setResult(data);
      return {result: data, attachment};
    }
    catch (e)
    {
      const err = e as AxiosSDKError;
      throw new ErrorHandler(err).message;
    }
  }
}

export const resultsStore: ResultsStore = new ResultsStore();
