import {action, makeObservable, observable} from 'mobx';
import {BaseStore} from '@infra/state-stores';
import {DiagnosticPrescriptionDTO, DiagnosticPrescriptionsApi} from '@infra/daslab-sdk';
import {AxiosError, AxiosResponse} from "axios";
import {AxiosSDKError} from "@infra/helpers";

export type prescriptions = Map<string, DiagnosticPrescriptionDTO>;
export interface IPrescriptionsStore {
  prescriptions: prescriptions;
  setPrescriptions: (prescriptions: prescriptions) => void;
  updatePrescriptions: () => void;
}

class PrescriptionsStore extends BaseStore<DiagnosticPrescriptionsApi> implements IPrescriptionsStore
{
  prescriptions: IPrescriptionsStore['prescriptions'] = new Map();

  constructor()
  {
    super(DiagnosticPrescriptionsApi);
    makeObservable(this, {
      prescriptions: observable,
      setPrescriptions: action,
      updatePrescriptions: action,
    });
  }

  updatePrescriptions = async (): Promise<void> =>
  {
    this.setExecuting(true);
    this.setErrors([]);

    try
    {
      const prescriptionList = await this.api.list();
      const prescriptions: prescriptions = new Map((prescriptionList?.data || []).map((prescription: DiagnosticPrescriptionDTO) => [prescription.id, prescription]));
      this.setPrescriptions(prescriptions);
      this.setInitiated(true);
    }
    catch (e)
    {
      const err = e as AxiosSDKError;
      this.setErrors(err);
    }
    finally
    {
      this.setExecuting(false);
    }
  }

  setPrescriptions = (prescriptions: prescriptions) => {this.prescriptions = prescriptions}
}

export const prescriptionsStore: PrescriptionsStore = new PrescriptionsStore();
