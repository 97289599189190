const CatchAll = '*'
const HomeRouter = '/'
const HomePage = '/home'
const Support = '/help'

const CollectPrescription = '/collect-prescription'
const CollectPrescriptionFailure = '/collect-prescription-failure'
const DiagnosticPrescriptions = '/diagnostic-prescriptions'
const DiagnosticPrescriptionsView = '/diagnostic-prescriptions-view'
const ShareResults = '/share-results'
const ShareInstructions = '/sharing-instructions'
const OrderDetail = '/orders';
const OrderConnect = '/orders/connect';
const OrderFollowup = '/orders/follow-up';

const SignIn = '/sign-in'
const Login = '/auth/login'
const Register = '/auth/register'
const AuthCheckInBox = '/auth/check-inbox'
const AuthConfirmEmail = '/auth/confirm-email'
const AuthResetPassword = '/auth/reset-password'
const AuthResetCheckInBox = '/auth/reset-check-your-inbox'
const AuthSetNewPassword = '/auth/set-new-password'

const IdentityConfirm = '/identity/confirm'
const IdentityCheckInbox = '/identity/check-inbox'
const IdentityCheckInboxCollect = '/identity/check-inbox-collect'

export const routes: Record<string, string> = {
  HomeRouter,
  HomePage,
  Support,
  CollectPrescription,
  CollectPrescriptionFailure,
  DiagnosticPrescriptions,
  DiagnosticPrescriptionsView,
  OrderDetail,
  OrderConnect,
  OrderFollowup,
  ShareResults,
  ShareInstructions,
  CatchAll,
  SignIn,
  Login,
  Register,
  AuthCheckInBox,
  AuthConfirmEmail,
  AuthResetPassword,
  AuthSetNewPassword,
  AuthResetCheckInBox,
  IdentityConfirm,
  IdentityCheckInbox,
  IdentityCheckInboxCollect,
}
