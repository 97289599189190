import { styled } from '@infra/ui-design-system'
import {Container} from "@mui/material";

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`
export const StyledFlexContainer = styled(Container)`
  display: flex;
  flex-grow: 1;
  ${({theme}) => `${theme.breakpoints.up('md')}{align-items: center}`}
`
