import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { routes } from '@/route.types'
import { AppFooter } from '@/shared/components/app-footer'
import { AppHeader } from '@/shared/components/app-header'
import { StyledPageContainer, StyledFlexContainer } from './LayoutWithOutlet.styled'
import {useTranslation} from "react-i18next";


export const LayoutWithOutlet = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t('shared.heading', {ns: 'shared'});
    if (location.pathname === '/') navigate(routes.HomePage);
  }, [location.pathname, navigate])

  return (
    <StyledPageContainer>
      <AppHeader />
      <StyledFlexContainer component="main" maxWidth="md" disableGutters>
        <Outlet />
      </StyledFlexContainer>
      <AppFooter />
    </StyledPageContainer>
  )
}
