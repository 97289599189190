import {FC, useEffect, useMemo} from "react";
import {getCookie} from "@infra/helpers";
import Smartlook from 'smartlook-client';

const getUmamiTag = (): HTMLScriptElement =>
{
  const script = document.createElement('script');
  script.async = true;
  script.id = 'daslab-umami';
  script.src = 'https://analytics.daslab.health/script.js';
  script.setAttribute('data-website-id', '8700b69b-136b-4874-8b7e-ed88a7b4f23b');
  script.type = 'text/javascript';
  return script;
}

export const TrackingTools: FC = () => {

  const consentCookie = useMemo<boolean>(() => !!getCookie('CookieConsent'), [getCookie('CookieConsent')])

  useEffect(() => {
    if(!consentCookie) return;

    Smartlook.init(import.meta.env.VITE_SMARTLOOK_API_KEY, { region: 'eu', cookies: false });
    document.body.appendChild(getUmamiTag());

    return () => {
      const umamiScript = document.querySelector('#daslab-umami') as HTMLScriptElement;
      umamiScript?.remove();
    };
  }, [consentCookie]);

  return null;
}
