import { Navigate, Outlet } from "react-router-dom";
import {observer} from "mobx-react";
import {useMemo} from "react";
import {authStore} from "@/shared/stores";
import {routes} from "@/route.types";
import {app, getAuth, useAuthState} from "@infra/firebase";


const ProtectedRoute = () => {
  const [user, loading, error] = useAuthState(getAuth(app))
  const {portal} = authStore;
  const redirectLoginUrl = useMemo<string>(() => portal === 'prevention' ? routes.Login : routes.IdentityConfirm, [portal]);
  const isFirebaseUserAuthenticated = useMemo<boolean>(() => !error && !!user, [user, loading, error]);

  if(loading) return null;
  return isFirebaseUserAuthenticated ? <Outlet /> : <Navigate to={redirectLoginUrl} />;
};

export default observer(ProtectedRoute);
