export type UserFormData = {
  email: string
  dateOfBirth: string
}

export type Consent = {
  primary: boolean
  secondary: boolean
}

export interface UserStoreState {
  error?: string | null
  user: UserFormData | null
}

export enum UserActionTypes {
  SET_USER_ERROR = 'SET_USER_ERROR',
  SET_USER_EMAIL = 'SET_USER_EMAIL',
  SET_USER_INPUTS = 'SET_USER_INPUTS',
  CLEAR_USER = 'CLEAR_USER',
}
