import { isAxiosError, AxiosError } from 'axios';

export type AxiosSDKError = AxiosError<{ messages: string[] }>;
export type AxiosErrorResponseData = { messages?: string[], error?: { message: string }, message?: string };
export type AxiosErrorType = AxiosError<AxiosErrorResponseData>;

type ErrorHandlerOptions = { defaultErrorMessage?: string };

export class ErrorHandler
{
  public error: unknown;
  private readonly defaultErrorMessage: string;

  constructor(error: unknown, options: ErrorHandlerOptions = {})
  {
    this.error = error;
    this.defaultErrorMessage = options.defaultErrorMessage || 'Unknown error';
  }

  get message(): string
  {
    if (typeof this.error === 'string') return this.error;
    if (isAxiosError(this.error)) return this.getAxiosErrorMessage(this.error);
    if (this.error instanceof Error) return this.error.message;
    return this.defaultErrorMessage;
  }

  get messages(): string[]
  {
    if (isAxiosError(this.error)) return this.error.response?.data?.messages || [this.defaultErrorMessage];
    return [this.defaultErrorMessage];
  }

  private getAxiosErrorMessage(error: AxiosErrorType): string
  {
    const response = error.response?.data;
    if (response?.messages?.length) return response.messages.join(', ');
    if (response?.error?.message) return response.error.message;
    if (response?.message) return response.message;
    return error.message || 'Unknown Axios error';
  }
}
