import { createContext } from 'react'
import { Store } from '../StateStore/StateStore'
import { Action } from '../StateStore/StateStore.interface'
import { PrescriptionActionTypes, PrescriptionStoreState } from './PrescriptionStore.interface'

export const initialStoreState: PrescriptionStoreState = {
  prescriptionId: undefined,
  practitioner: undefined,
}

export const storeReducer = (
  state: PrescriptionStoreState,
  action: Action<PrescriptionActionTypes>
) => {
  switch (action.type) {
    case PrescriptionActionTypes.SET_PRESCRIPTION_ID:
      return {
        ...state,
        prescriptionId: action.payload,
      }
    case PrescriptionActionTypes.SET_PRACTITIONER_INFO:
      return {
        ...state,
        practitioner: action.payload,
      }
    default:
      return state
  }
}

export const prescriptionStore = new Store<PrescriptionStoreState, PrescriptionActionTypes>(
  storeReducer,
  initialStoreState
)

export const PrescriptionStoreContext = createContext(prescriptionStore)

export const PrescriptionStoreProvider = ({ children }: { children: any }) => {
  return (
    <PrescriptionStoreContext.Provider value={prescriptionStore}>
      {children}
    </PrescriptionStoreContext.Provider>
  )
}
