import {action, computed, makeObservable, observable} from 'mobx';
import {BaseStore} from '@infra/state-stores';
import {OrderProductResponseDTO, GetOrderResponseDTO, OrdersApi} from '@infra/daslab-sdk';
import {AxiosResponse} from "axios";
import {AxiosSDKError} from "@infra/helpers";

export type OrderProduct = OrderProductResponseDTO & {orderId: string};

export type orders = Map<string, GetOrderResponseDTO>;
export interface IOrderStore {
  orders: orders;
  products: Array<OrderProduct>;
  getOrderByReference: (reference: GetOrderResponseDTO['reference']) => GetOrderResponseDTO|null;
  getProductByOrderId: (orderProductId: OrderProduct['id']) => OrderProduct|null;
  updateOrders: () => Promise<void>;
  setOrders: (orders: IOrderStore['orders']) => void;
  addOrder: (orders: GetOrderResponseDTO) => void;
}

export interface IResponseListOrders {
  data: Array<GetOrderResponseDTO>;
  meta: {total: number};
}

class OrderStore extends BaseStore<OrdersApi> implements IOrderStore
{
  orders: IOrderStore['orders'] = new Map();

  constructor()
  {
    super(OrdersApi);
    makeObservable(this, {
      orders: observable,
      products: computed,
      getProductByOrderId: observable,
      getOrderByReference: action,
      setOrders: action,
      addOrder: action,
      updateOrders: action,
    });
  }

  get products()
  {
    const products: Array<OrderProduct> = [];
    this.orders.forEach((order: GetOrderResponseDTO) =>
    {
      Array.prototype.push.apply(
        products,
        order.products.map(product => (
          {...product, orderId: order.id})
        ))
    });
    return products;
  }

  getProductByOrderId = (orderProductId: OrderProduct['id']) => this.products.find(product => product.orderProductId === orderProductId) || null;

  getOrderByReference = (reference: GetOrderResponseDTO['reference']): GetOrderResponseDTO|null => {
    for(const order of this.orders.values()) if(order.reference === reference) return order;
    return null;
  }
  addOrder = (order: GetOrderResponseDTO): void => {this.orders.set(order.id, order)}
  updateOrders = async (): Promise<void> =>
  {
    this.setExecuting(true);
    this.setErrors([]);

    try
    {
      const orderList = await this.api.list(['-createdAt']) as unknown as AxiosResponse<IResponseListOrders>;
      const orders: orders = new Map(orderList?.data?.meta?.total ? orderList.data.data.map((order: GetOrderResponseDTO) => [order.id, order]) : []);
      this.setOrders(orders);
      this.setInitiated(true);
    }
    catch (e)
    {
      const err = e as AxiosSDKError;
      this.setErrors(err);
    }
    finally
    {
      this.setExecuting(false);
    }
  }

  setOrders = (orders: orders): void => {this.orders = orders}
}

export const orderStore: OrderStore = new OrderStore();
