import { routes } from '@/route.types'
import { LogoPatients, useTheme, FeatureBurger } from '@infra/ui-design-system'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { AppBar, Toolbar, IconButton, List, Drawer, ListItemButton, ListItemText, Box } from "@mui/material";
import { authStore } from "@/shared/stores";
import { getSupportWebsiteUrl } from "@infra/helpers";
import { auth, signOut } from "@infra/firebase";

export const AppHeader = () => {
  const anchor = 'left';

  const [open, setOpen] = useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const navigate = useNavigate();
  const {palette, breakpoints, spaces, spacing, background, sizes} = useTheme();
  const {portal} = authStore;
  const { t } = useTranslation('shared');

  const handleNavigate = (route: string, blankTarget?: boolean): void => {
    setOpen(false);
    if(!blankTarget) return navigate(route);
    window.open(route, '_blank')
  }

  const logout = async () => {
    setOpen(false);
    await signOut(auth);
    portal === 'prevention' ? navigate(routes.Login) : navigate(routes.IdentityConfirm);
  }

  const toggleDrawer = (_anchor: typeof anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) return;
    setOpen(open);
  };

  useEffect(() => {
    (async () => {
      const accessToken: string|Promise<string> = await auth.currentUser?.getIdToken();
      setIsUserLogged(!!accessToken);
    })()
  }, [auth.currentUser]);

  return <Box>
    <Drawer
        anchor={anchor}
        onClose={toggleDrawer(anchor, false)}
        open={open}
      >
        <nav aria-label={'navigation'}>
          <List
            sx={{
              padding: `${spacing(spaces.main)} ${spacing(spaces.main * 2)} ${spacing(spaces.main)} ${spacing(spaces.main)}`,
              width: {xs: '75vw', md: sizes.drawer}
            }}>
            <Box pl={spaces.main} mb={spaces.main}>
              <IconButton sx={{paddingRight: 0, transform: 'translateY(2px)'}} onClick={toggleDrawer(anchor, false)} edge="start" color="inherit" aria-label="menu" >
                <FeatureBurger width={16} height={16} sx={{color: palette.text.primary}} />
              </IconButton>
              <IconButton sx={{paddingLeft: 0}} onClick={()=> {navigate(routes.HomePage)}}>
                <LogoPatients height={16} color={palette.text.primary} secondaryColor={palette.primary.main} />
              </IconButton>
            </Box>
            <ListItemButton onClick={() => {handleNavigate(routes.HomePage)}}>
              <ListItemText primary={'Home'} />
            </ListItemButton>
            <ListItemButton onClick={() => {handleNavigate(getSupportWebsiteUrl(), true)}}>
              <ListItemText primary={t('shared.support')} />
            </ListItemButton>
            {isUserLogged && <ListItemButton onClick={logout}>
              <ListItemText primary={t('shared.logout')} />
            </ListItemButton>}
          </List>
        </nav>
      </Drawer>
    <AppBar sx={{background: {xs: background.secondary, md: 'white'}}} elevation={0}>
      <Toolbar>
        <IconButton sx={{paddingRight: 0, transform: 'translateY(2px)'}} onClick={toggleDrawer(anchor, true)} edge="start" color="inherit" aria-label="menu" >
          <FeatureBurger width={16} height={16} sx={{color: palette.text.primary}} />
        </IconButton>
        <IconButton sx={{paddingLeft: 0}} onClick={()=> {navigate(routes.HomePage)}}>
          <LogoPatients height={16} color={palette.text.primary} secondaryColor={palette.primary.main} />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Toolbar />
  </Box>;
}
