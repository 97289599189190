import {useTranslation} from "react-i18next";
import {Box, Divider, Grid, Link as MuiLink, Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@infra/ui-design-system";
import React from "react";

export const AppFooter = () => {
  const {t, i18n} = useTranslation();
  const {breakpoints, spaces, spacing, palette} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  return <footer>
    <Box sx={{
      color: palette.grey[600],
      padding: {
        xs: `${spacing(spaces.big)} ${spacing(spaces.main)}`,
        md: `${spacing(spaces.main)} ${spacing(spaces.main)}`,
      },
    }}>
      <Grid container spacing={spaces.big}>
        <Grid item xs={12} md={'auto'} sx={{order: {xs: 1, md: 0}}}>
          {isMobile && <Box mb={spaces.big}><Divider /></Box>}
          <Typography variant={'body2'}>© Das Lab GmbH</Typography>
        </Grid>
        <Grid item xs={12} md={'auto'}>
          <MuiLink href={`${import.meta.env.VITE_WEBSITE_URL}${i18n.language.split('_')[0]}/legal/terms`} underline={'hover'} color={'inherit'} target={'_blank'}><Typography variant={'body2'}>{t('shared.footer.termsAndConditions', {ns: 'shared'})}</Typography></MuiLink>
        </Grid>
        <Grid item xs={12} md={'auto'}>
          <MuiLink href={`${import.meta.env.VITE_WEBSITE_URL}${i18n.language.split('_')[0]}/legal/privacy`} underline={'hover'} color={'inherit'} target={'_blank'}><Typography variant={'body2'}>{t('shared.footer.privacy', {ns: 'shared'})}</Typography></MuiLink>
        </Grid>
        <Grid item xs={12} md={'auto'}>
          <MuiLink onClick={() => {window.Cookiebot?.show && window.Cookiebot?.show(true)}} underline={'hover'} color={'inherit'}><Typography variant={'body2'}>Cookies</Typography></MuiLink>
        </Grid>
        <Grid item xs={12} md={'auto'}>
          <MuiLink href={`${import.meta.env.VITE_WEBSITE_URL}legal/imprint`} underline={'hover'} color={'inherit'} target={'_blank'}><Typography variant={'body2'}>Imprint</Typography></MuiLink>
        </Grid>
      </Grid>
      {!isMobile && <Box mt={spaces.main}><Divider /></Box>}
    </Box>
  </footer>;
}
