import { InitOptions } from 'i18next'
import { getLanguageCodeFromBrowser } from '@infra/helpers'

export const i18nextConfig: InitOptions = {
  debug: true,
  load: 'languageOnly',
  fallbackLng: 'de_DE',
  lng: getLanguageCodeFromBrowser(),
  ns: [
    'collect-prescription',
    'identity',
    'common',
    'orders',
    'products',
    'shared',
    'share-results',
    'diagnostic-request-parameters'
  ],
  defaultNS: 'collect-prescription',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: false,
  },
  initImmediate: false,
  parseMissingKeyHandler: () => '',
}
