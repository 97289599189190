import { Navigate, Route, Routes } from 'react-router-dom'
import { LazyRoutes } from './lazy-routes'
import { routes } from './route.types'

export const RoutesProvider = () => {
  return (
    <Routes>
      <Route path={routes.HomeRouter} element={<LazyRoutes.LayoutWithOutlet />}>
        <Route element={<LazyRoutes.ProtectedRoute />}>
          <Route path={routes.HomePage} element={<LazyRoutes.HomePage />} />
          <Route path={routes.OrderConnect} element={<LazyRoutes.OrderConnect />} />
          <Route path={`${routes.OrderDetail}/:id`} element={<LazyRoutes.OrderDetail />} />
          <Route path={`${routes.OrderFollowup}/:containerId`} element={<LazyRoutes.OrderFollowup />} />
          <Route path={routes.DiagnosticPrescriptions} element={<LazyRoutes.DiagnosticPrescriptions />}/>
          <Route path={`${routes.DiagnosticPrescriptions}/:id`} element={<LazyRoutes.CollectPrescription />}/>
          <Route path={`${routes.DiagnosticPrescriptionsView}/:id`} element={<LazyRoutes.ViewPrescription />}/>
          <Route path={routes.ShareResults} element={<LazyRoutes.ShareResults />} />
          <Route path={routes.ShareInstructions} element={<LazyRoutes.ShareInstructions />} />
        </Route>

        <Route path={routes.CollectPrescription} element={<LazyRoutes.CollectPrescription />} />
        <Route path={routes.Support} element={<LazyRoutes.Support />} />
        <Route path={routes.CollectPrescriptionFailure} element={<LazyRoutes.CollectPrescriptionFailure />}/>
        <Route path={routes.IdentityConfirm} element={<LazyRoutes.IdentityConfirm />} />
        <Route path={routes.IdentityCheckInbox} element={<LazyRoutes.IdentityCheckInbox />} />
        <Route path={routes.IdentityCheckInboxCollect} element={<LazyRoutes.IdentityCheckInbox variant={'collect'} />} />
        <Route path={routes.SignIn} element={<LazyRoutes.SignIn />} />

        <Route element={<LazyRoutes.OnlyUnauthorizedRoute />}>
          <Route path={routes.Login} element={<LazyRoutes.Login />} />
          <Route path={routes.Register} element={<LazyRoutes.Register />} />
        </Route>
        <Route path={routes.AuthCheckInBox} element={<LazyRoutes.AuthCheckInBox />} />
        <Route path={routes.AuthConfirmEmail} element={<LazyRoutes.AuthConfirmEmail />} />
        <Route path={routes.AuthResetPassword} element={<LazyRoutes.AuthResetPassword />} />
        <Route path={routes.AuthSetNewPassword} element={<LazyRoutes.AuthSetNewPassword />} />
        <Route path={routes.AuthResetCheckInBox} element={<LazyRoutes.AuthResetCheckInBox />} />

        <Route path={routes.CatchAll} element={<Navigate to={routes.Error} replace />} />
      </Route>
    </Routes>
  )
}
